/**
 * Core
 */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_EVENTS_BY_TOPIC } from '../utils/gql'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

const Latest = ({ data }) => {
    useScrollTrigger('.reveal')

    const isLink = (link) => {
        const expression = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gi
        const regex = new RegExp(expression)
        return link ? link.match(regex) ? true : false : false
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const getDate = date => {
        const dd = new Date(date.slice(0,-6))
        const d = dd.getDate()
        const m = dd.getMonth()
        const y = dd.getFullYear()
        return {
            text: `${months[m]} ${d}, ${y}`,
            sql: `${y}-${m<9?'0'+(m+1):(m+1)}-${d<10?'0'+d:d}`,
        }
    }

    return (
        <div className='reveal top delay-300'>
            <div className='bg-white p-8 mt-8 flex flex-col'>
                <h4 className='font-semibold flex items-center mb-8 pt-2'>Next Event <span className="ml-4 text-3xl font-symbol">event</span></h4>
                <div className='flex flex-col sm:flex-row justify-between items-center mb-8'>
                    <span className='font-bold text-3xl leading-none mr-8 bg-clip-text text-transparent bg-gradient-analagous-rose-triad-orange'>{data.title}</span>
                    <img className='w-28 mt-8 sm:mt-0 rounded-full self-start' src={data.events.logo.node.sourceUrl} alt='' />
                </div>
                
                <div>Hosted by</div>
                { data.events.hostedBy ? <div className='font-semibold'>{data.events.hostedBy}</div> : '' }
                <div>Date</div>
                <div className='font-semibold'>{getDate(data.sortableKeydate.date).text}</div>
                { isLink(data.events.joinUs) && <Link to={data.events.joinUs} target='_blank' rel='noopener noreferrer'><button className='dark self-end'><div>More Info</div></button></Link> }
            </div>
        </div>
    )
}

const Announcement = ({ topic }) => {

    const { data, loading, error, fetchMore } = useQuery(GET_EVENTS_BY_TOPIC, {
        variables: { topicSlug: [topic] },
        notifyOnNetworkStatusChange: true,
    })

    const [latest, setLatest] = useState()

    const getEvents = (data) => {
        let events = []
        data.map( event => {
            const obj = {
                title: event.title,
                events: event.events,
                eventtypes: event.eventtypes,
                regions: event.regions,
                topics: event.topics,
                keyDates: [],
                sortableKeydate: null
            }
            if(event.events.keyDates.keyDate !== null) obj.keyDates.push({date: event.events.keyDates.keyDate, label: event.events.keyDates.keyDateLabel})
            if(event.events.keyDates.keyDate1 !== null) obj.keyDates.push({date: event.events.keyDates.keyDate1, label: event.events.keyDates.keyDateLabel1})
            if(event.events.keyDates.keyDate2 !== null) obj.keyDates.push({date: event.events.keyDates.keyDate2, label: event.events.keyDates.keyDateLabel2})
            obj.keyDates.sort((date1, date2) =>  date1.date > date2.date ? 1 : -1)
            obj.sortableKeydate = obj.keyDates.find(keydate => {
                const d = new Date(keydate.date)
                return d > Date.now() ? keydate : null
            })
            events.push(obj)
        })
        events = [
            ...events.filter(event => event.sortableKeydate).sort((a,b) => a.sortableKeydate.date > b.sortableKeydate.date ? 1 : -1),
            ...(events.filter(event => !event.sortableKeydate))
        ]
        return events
    }

    useEffect(() => {
        if(data) setLatest(getEvents(data.events.nodes)[0])
    }, [data])

    if(loading) return <></>
    
    return (
        latest && <Latest data={latest} />
    )
}
export default Announcement